.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo,
.duclo_logo img{
  height: 44px;
}

.navbar-light .navbar-brand.duclo_logo{
  color: #35B9B2;
  font-size: 26px;
  text-transform: uppercase;
}

.navbar-light .navbar-brand.duclo_logo:hover{
  color: #35B9B2;
  text-transform: uppercase;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav {
    align-items: end;
    justify-content: end;
  }
}

.sign_up form{
  text-align: left;
}

#duclo-top-bar{
  height: 100px;
  background-color: #EDEDED;
}

.sidebar{
  background-color: #D7D7D7;
}

.sidebar-content{
  height: 100%;
  /* min-height: 100vh; */
}

.sidebar-content .navbar-nav{
  align-items: start;
  justify-content: start;
}

.sidebar-content a{
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  height: 54px;
  color: #000000;
}

.sidebar-content .navbar-light .navbar-nav .nav-link:hover,
.sidebar-content .navbar-light .navbar-nav .nav-link:focus{
  color: #04A89F;
  font-weight: 700;
}

.sidebar-content a svg{
  margin-right: 10px;
}

#duclo-top-bar .top-bar-menu{
  justify-content: end;
}

.top-bar-menu a{
  color: #000000;
  text-decoration: none;
}

.top-bar-menu a:hover,
.top-bar-menu a:focus{
    color: #04A89F;
}

.top-bar-menu .dropdown .btn-primary{
  background-color: transparent !important;
  border-color: transparent !important;
  color: #000000 !important;
  box-shadow: unset !important;
}

.top-bar-menu .dropdown .dropdown-toggle::after{
  display: none;
}

.top-bar-menu .dropdown .dropdown-item.active,
.top-bar-menu .dropdown .dropdown-item:active{
  background-color: transparent !important;
  border-color: transparent !important;
}

p{
  font-size: 16px;
}

.sign_in_btn{
  border-radius: 2px;
  background-color: #05A89F !important;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-style: none;
  height: 40px;
  min-width: 130px;
}

.page-title{
  text-align: left;
  font-family: Lato;
  font-size: 32px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #302E32;
  margin-bottom: 25px;
}

/* Duclo Breadcrumbs */
.duclo-breadcrumbs a,
.duclo-breadcrumbs a:hover,
.duclo-breadcrumbs a:focus{
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #545255;
  text-decoration: none;
}

.duclo-breadcrumbs a > svg{
  margin-right: 10px;
}

.duclo-breadcrumbs .breadcrumb-item + .breadcrumb-item{
  padding-left: 0;
}

.duclo-breadcrumbs .breadcrumb-item + .breadcrumb-item::before{
  content: var(--bs-breadcrumb-divider, "") /* rtl: var(--bs-breadcrumb-divider, "") */;
}

.duclo-breadcrumbs .svg-inline--fa{
  vertical-align: middle !important;
}

.page-header{
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.page-header .nav{
  justify-content: end;
}

.gray-button,
.page-header .gray-button{
  background: #B0ADAD !important;
  border: 1px solid #000000 !important;
  border-radius: 10px;
  margin-right: 10px;
  color: #000 !important;
}

.gray-button:hover,
.gray-button:focus,
.gray-button:active,
.page-header .gray-button:hover,
.page-header .gray-button:focus,
.page-header .gray-button:active{
  background: #B0ADAD !important;
  color: #000 !important;
  border: 1px solid #000000 !important;
  box-shadow: unset;
}

.btn-link,
.btn-link:hover,
.btn-link:focus{
  color: #B6B5B7;
  text-decoration: none;
}

.main-content-before{
  display: table;
  background: #ddd;
  padding: 10px 30px;
}

.main-content {
    background-color: #ECEBEC;
    text-align: left;
    padding: 0 50px;
    display: grid;
}

.duclo-table thead tr{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #302E32;
  border-bottom: solid 1px #D7D7D7;
}

.duclo-table tbody tr{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #302E32;
  border-bottom: solid 1px #D7D7D7;
}

.duclo-table tr th,
.duclo-table tr td{
  padding: 10px;
}

.btn-action{
  color: #545255 !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active{
  box-shadow: unset;
}


.site-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #fff;
  --_m:
    conic-gradient(#0000 2%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: s3 1s infinite linear;
}
@keyframes s3 {to{transform: rotate(1turn)}}

.row .site-spinner{
  padding-left: 5px;
  padding-right: 5px;
}
.select-container{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 30px;
  align-items: center;
  gap: 16px;
  .select-wrapper{
    width: 250px;
  }
}
.status-container {
  height: 30px;
  padding: 8px 16px;
  border: 1.5px solid;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  white-space: nowrap;
}
.pending-activation{
  color: #4ea65a;
  border-color: #4ea65a;
  background-color: #f1f8f2;
}
.rejected{
  color: #DE6868;
  border-color: #DE6868;
  background-color:#FBEEEE;
}
.pending-approval{
  color: #0052CC;
  border-color: #0052CC;
  background-color:#EBF3FF;
}
.accept-reject{
  height: 30px;
  padding: 8px 16px;
  border: 1.5px solid #04A89F;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #04A89F;
  margin-right: 10px;
  background-color: white;
  white-space: nowrap;
  gap: 4px;
}
.table-overflow{
  overflow-x: auto;
}